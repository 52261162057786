exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs/[...].tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-customs-tsx": () => import("./../../../src/pages/customs.tsx" /* webpackChunkName: "component---src-pages-customs-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-fulfillment-policy-tsx": () => import("./../../../src/pages/fulfillment-policy.tsx" /* webpackChunkName: "component---src-pages-fulfillment-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-waitlist-tsx": () => import("./../../../src/pages/join-waitlist.tsx" /* webpackChunkName: "component---src-pages-join-waitlist-tsx" */),
  "component---src-pages-payment-success-tsx": () => import("./../../../src/pages/payment-success.tsx" /* webpackChunkName: "component---src-pages-payment-success-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-quiz-tsx": () => import("./../../../src/pages/quiz.tsx" /* webpackChunkName: "component---src-pages-quiz-tsx" */),
  "component---src-pages-science-tsx": () => import("./../../../src/pages/science.tsx" /* webpackChunkName: "component---src-pages-science-tsx" */),
  "component---src-pages-vitract-university-tsx": () => import("./../../../src/pages/vitract-university.tsx" /* webpackChunkName: "component---src-pages-vitract-university-tsx" */)
}

